import 'classlist.js';
import 'web-animations-js';  
import 'zone.js/dist/zone'; 


// Usar para funcionar no IE 11
if (!Object.values) Object.values = o=>Object.keys(o).map(k=>o[k]);
if (!Array.prototype.includes) {
    Object.defineProperty(Array.prototype, 'includes', {
      value: function (searchElement, fromIndex) {
  
        if (this == null) {
          throw new TypeError('"this" is null or not defined');
        }
  
        var o = Object(this);
        var len = o.length >>> 0;
  
        if (len === 0) {
          return false;
        }
  
        var n = fromIndex | 0;
  
        var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);
  
        function sameValueZero(x, y) {
          return x === y || (typeof x === 'number' && typeof y === 'number' && isNaN(x) && isNaN(y));
        }
  
        while (k < len) {
          if (sameValueZero(o[k], searchElement)) {
            return true;
          }
          k++;
        }
  
        return false;
      }
    });
  }